import react from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Cookie from "js-cookie";
import commonData from "../../importanValue";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import audioFile from "./audio2.wav";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import "./styles.css";
import { withRouter } from "react-router-dom";
import Chat from "./Chat";
import AdminChat from "./AdminChat";
import { connect } from "react-redux";
import { storeinfo } from "../../redux/reducer";

class Support extends react.Component {
  state = {
    popUpOpen: !true,
    showContent: !true,
    supportHomeData: [],
    clickedId: null,
    title: "",
    search: "",
    autoRespondData: [],
    chattext: "",
    searchInput: "",
    searchClicked: false,
    autoMsgSelected: false,
    autoMsgData: [],
    homeAutoData: [],
    userNum: "",
  };

  componentDidMount() {
    const { location, supportHomeData, homeAutoData } = this.props;
    if (location.pathname.includes("/support/user/")) {
      this.setState({
        popUpOpen: true,
        showContent: true,
        clickedId: 2,
        userNum: location.pathname.split("/support/user/")[1],
      });
    }
    if (supportHomeData.length === 0) {
      this.getData();
    } else {
      this.setState({
        supportHomeData,
      });
    }
    if (homeAutoData.length === 0) {
      this.getChatDataDefault();
    } else {
      this.setState({
        homeAutoData,
      });
    }
  }
  // componentWillUnmount() {
  //   this.setState({
  //     popUpOpen: false,
  //     showContent: false,
  //     clickedId: "",
  //     userNum: "",
  //   });
  // }

  handleOpen = () => {
    this.setState((p) => ({
      showContent: false,
      popUpOpen: !p.popUpOpen,
    }));
  };

  handleSearchClose = () => {
    this.setState((p) => ({
      searchClicked: false,
      autoMsgSelected: false,
    }));
  };

  getData = async () => {
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "supportHomeAll",
      search: "",
      qid: 0,
    };
    try {
      this.setState({ isLoading: true });
      console.log(commonData["api"]);
      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      if (data.data[0].length > 0) {
        const audio = new Audio(audioFile);
        audio.play();
      }
      this.setState({
        isLoading: false,
        supportHomeData: data.data[0],
      });
      this.props.storeinfo({ supportHomeData: data.data[0] });
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  renderLeftData = (ee) => {
    const { descriptionText, id, title } = ee;
    return (
      <div
        className="eachData"
        onClick={() =>
          this.setState((p) => ({
            showContent: !p.showContent,
            clickedId: id,
            title: title,
            chattext: ee.chattext,
          }))
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <h4>{title}</h4>
            </div>
            <div style={{ fontWeight: 200, opacity: 0.6 }}>
              <p>{descriptionText}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            ➤{" "}
          </div>
        </div>
        <hr style={{ marginTop: 5, opacity: 0.6 }} />
      </div>
    );
  };

  getChatDataDefault = async () => {
    const { clickedId } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "getChatData",
      search: "",
      qid: 1,
    };
    try {
      this.setState({ isLoading: true });

      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      console.log(data.data[0]);
      const body2 = {
        type: "getAds",
        search: "",
        qid: 0,
      };

      const data2 = await axios.post(`${commonData["api"]}/support`, body2, {
        headers,
      });
      console.log(
        "🚀 ~ file: Support.js:192 ~ Support ~ getChatDataDefault= ~ data2:",
        data2
      );
      localStorage.setItem("ads", JSON.stringify(data2.data[0]));

      this.setState({
        isLoading: false,
        homeAutoData: data.data[0],
      });
      this.props.storeinfo({ homeAutoData: data.data[0] });
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  getChatData = async () => {
    const { clickedId, searchInput } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "getChatData",
      search: searchInput,
      qid: clickedId,
    };
    try {
      this.setState({ isLoading: true, searchClicked: true });

      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      console.log(data.data[0]);
      this.setState({
        isLoading: false,
        autoRespondData: data.data[0],
      });
    } catch (err) {
      NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };
  renderMessageSelected = () => {
    const { autoMsgData } = this.state;
    return (
      <>
        <p className="messagesauto sent">{autoMsgData.recieve}</p>

        <p className="messagesauto msgrecived">
          <span
            dangerouslySetInnerHTML={{
              __html: autoMsgData.reply + "<br/><br/>",
            }}
          ></span>
        </p>

        <p
          className="messagesauto startagain"
          onClick={() =>
            this.setState({
              showContent: true,
              autoMsgSelected: false,
              searchClicked: false,
              autoMsgData: [],
              searchInput: "",
              autoRespondData: [],
            })
          }
        >
          Search Again
        </p>
      </>
    );
  };
  sendMsg = async () => {
    const { searchInput } = this.state;
    if (searchInput === "") {
      NotificationManager.error(`Please enter search input`);
    } else {
      try {
        await this.getChatData();
      } catch {
        NotificationManager.error(`Something Went Wrong`);
      }
    }
  };
  renderContent = () => {
    const {
      autoRespondData,
      chattext,
      homeAutoData,
      searchInput,
      searchClicked,
    } = this.state;
    return (
      <div>
        {searchClicked && (
          <>
            <p style={{ textAlign: "center", paddingTop: 20 }}>
              You Searched for{" "}
              <span style={{ fontSize: 23 }}>{searchInput}</span>
              .Please select from below
            </p>
            <div
              className="chatdiv"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {autoRespondData.map((e) => (
                <p
                  key={"messagesauto" + e.id}
                  className="messagesauto"
                  onClick={() =>
                    this.setState({ autoMsgSelected: true, autoMsgData: e })
                  }
                >
                  {e.recieve}
                </p>
              ))}
            </div>
          </>
        )}
        {!searchClicked && (
          <>
            <p style={{ textAlign: "center", paddingTop: 20 }}>{chattext}</p>
            <div className="searchdiv">
              <input
                value={searchInput}
                onChange={(e) =>
                  this.setState({ searchInput: e.target.value.toUpperCase() })
                }
                style={{ width: "80%", marginRight: 10 }}
                placeholder="Enter Like APTET,TSTET...."
              />
              <div onClick={this.sendMsg} className="sendmsgicon">
                <SendIcon />
              </div>{" "}
            </div>
            <div
              className="chatdiv"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {homeAutoData.map((e) => (
                <p
                  key={"messagesauthomeo" + e.id}
                  className="messagesauto"
                  onClick={() =>
                    this.setState({ autoMsgSelected: true, autoMsgData: e })
                  }
                >
                  {e.recieve}
                </p>
              ))}
            </div>
          </>
        )}
        {!searchClicked ? (
          <p
            className="messagesauto startagain"
            onClick={() =>
              this.setState((p) => ({
                showContent: !p.showContent,
                autoMsgSelected: false,
                autoMsgData: [],
                searchInput: "",
                searchClicked: false,
                showContent: false,
              }))
            }
            style={{ cursor: "pointer" }}
          >
            Back
          </p>
        ) : (
          <p
            className="messagesauto startagain"
            onClick={() =>
              this.setState({
                showContent: true,
                autoMsgSelected: false,
                searchClicked: false,
                autoMsgData: [],
                searchInput: "",
                autoRespondData: [],
              })
            }
          >
            Search Again
          </p>
        )}
      </div>
    );
  };

  CloseUserChat = () => {
    this.setState({ showContent: false, userNum: "" });
  };
  renderPopUp = () => {
    const {
      popUpOpen,
      showContent,
      supportHomeData,
      title,
      searchClicked,
      autoMsgSelected,
      clickedId,
      userNum,
    } = this.state;
    const { location, history } = this.props;
    const name = localStorage.getItem("name");
    const token = Cookie.get("jwt_token");
    return (
      <Dialog
        open={popUpOpen}
        onClose={this.handleOpen}
        maxWidth={"sm"}
        // fullWidth
        fullScreen
      >
        {!showContent ? (
          <DialogTitle id="alert-dialog-title" className="supportdailog">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#FFFF",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                Hi {name}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                >
                  <SentimentSatisfiedAltIcon />
                </div>
              </div>
              <div className="closebtn">
                <Button onClick={this.handleOpen} style={{ color: "#fff" }}>
                  X
                </Button>
              </div>
            </div>
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title" className="supportdailog">
            <div
              style={{
                display: "flex",
                color: "#FFF",
                justifyContent: "space-between",
              }}
            >
              {/* {!autoMsgSelected && (
                <div
                  onClick={() =>
                    this.setState((p) => ({
                      showContent: !p.showContent,
                      autoMsgSelected: false,
                      autoMsgData: [],
                    }))
                  }
                  style={{ cursor: "pointer" }}
                >
                  <p style={{ fontSize: 28, backgroundColor: "transparent" }}>
                    🔙
                  </p>{" "}
                </div>
              )} */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h5 style={{ textAlign: "center", marginLeft: 15 }}>{title}</h5>
              </div>
              <div className="closebtn">
                {showContent && clickedId === 1 && (
                  <Button
                    onClick={
                      searchClicked || autoMsgSelected
                        ? this.handleSearchClose
                        : this.handleOpen
                    }
                    style={{ color: "#fff" }}
                  >
                    X
                  </Button>
                )}
                {showContent && clickedId === 2 && (
                  <Button
                    onClick={
                      location.pathname.includes("/support/user/")
                        ? history.replace("/")
                        : this.CloseUserChat
                    }
                    style={{ color: "#fff" }}
                  >
                    X
                  </Button>
                )}
              </div>
            </div>
          </DialogTitle>
        )}
        <DialogContent className="dailogContent2" style={{ display: "flex" }}>
          {!showContent && (
            <div className="sidebar-chat">
              {supportHomeData.map((ee) => (
                <div key={"supporttitles" + ee.id}>
                  {this.renderLeftData(ee)}
                </div>
              ))}
            </div>
          )}
          {showContent && clickedId === 1 && (
            <div className="message-content">
              <div className="chatData">
                {" "}
                {autoMsgSelected
                  ? this.renderMessageSelected()
                  : this.renderContent()}
              </div>
            </div>
          )}
          {showContent &&
            token !== undefined &&
            clickedId === 2 &&
            (localStorage.getItem("user") === "0" ||
            localStorage.getItem("user") === "2" ? (
              <Chat />
            ) : (
              <AdminChat num={userNum} />
            ))}
        </DialogContent>
      </Dialog>
    );
  };
  render() {
    return (
      <>
        <div
          className="support"
          onClick={this.handleOpen}
          style={{
            display:
              this.props.location.pathname.split("/")[1] === "attempt-exam" &&
              "none",
          }}
        >
          {/* <ForumIcon
            className="home-button marg"
            style={{ color: "white", margin: "auto" }}
          />{" "} */}
          HelpLine
        </div>
        {/* <div className="notificationCounter">
          <img src="https://icon-library.com/images/new-icon-gif/new-icon-gif-13.jpg" />
        </div> */}
        {this.renderPopUp()}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { homeAutoData, supportHomeData } = state.common;
  return { homeAutoData, supportHomeData };
};

const mapDispatchToProps = { storeinfo };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Support));
